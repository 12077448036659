import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Layout from '../components/layout'
import SEO from '../components/seo'
import docx from '../lib/docx'

// TODO fieldguides image
const FieldGuidesPage = ({ data }) => {
  const fieldGuides = docx(data)
  return (
    <Layout>
      <SEO title="Field Guides" />
      <div>
        <p>We've managed to scratch together a few notes and field guides pertaining to the world of Th'eia and its inhabitants. We'll keep adding new information as it comes to us.</p>
        <Tabs>
          <TabList>
            {fieldGuides.map(({ title }) => <Tab>{title}</Tab>)}
          </TabList>
          {fieldGuides.map(({ content }) => <TabPanel><div dangerouslySetInnerHTML={{ __html: content }} /></TabPanel>)}
        </Tabs>
      </div>
    </Layout >
  )
}

export default FieldGuidesPage

export const query = graphql`
query FieldGuidesQuery {
  allDocx(filter: {name: {glob: "fieldguides_*"}}) {
    nodes {
      name
      content
    }
  }
}
`